import React from 'react';
import { useLocales } from '../../../hooks';
import { useRecoilValue } from 'recoil';
import { Download } from '@mui/icons-material';
import { ExportEntityType } from '../../../API';
import { withStatsigUiModulesDefaultConfig, withUiModulesConfigByType } from '../../../state/Experiments';
import { useData } from '../../../data-layer';
import IconButton from '../../shared/IconButton';
import { UIModuleType } from '../../../utils/layouts';

type UIModuleExportProps = {
  moduleId: string;
  moduleType: UIModuleType;
};

function UIModuleExport({ moduleId, moduleType }: UIModuleExportProps): JSX.Element | null {
  const {
    dataExchanges: {
      hook: { exportEntity }
    }
  } = useData();
  const { t } = useLocales();

  const uiModulesDefaultConfig = useRecoilValue(withStatsigUiModulesDefaultConfig);
  const uiModuleConfig = useRecoilValue(withUiModulesConfigByType(moduleType));
  const isExchangeable = uiModuleConfig?.exchangeable ?? uiModulesDefaultConfig?.exchangeable;

  const handleExport = async () => {
    await exportEntity(moduleId, ExportEntityType.UI_MODULE);
  };

  return isExchangeable ? (
    <IconButton color="secondary" onClick={handleExport} title={t('layouts.export_ui_module')}>
      <Download />
    </IconButton>
  ) : null;
}

export default UIModuleExport;
