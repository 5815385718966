import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { isEqual } from 'lodash-es';
import { PageLayoutResponseV2, PermissionsGroupId, VersionedDocumentBody, VersionedDocumentStatus } from '../../../API';
import { useLocales, useNavigationGuard } from '../../../hooks';
import { usePrompt } from '../../../hooks/General/usePrompt';
import Button from '../../shared/Button';
import { useGlobalHistoryManager } from '../../../hooks/General/useGlobalHistoryManager';
import { Tooltip } from '@mui/material';
import { ctrlKeyString } from '../../../utils/userPlatform';
import { useRecoilState } from 'recoil';
import { withHistoryManager } from '../../../state/General/withHistoryManager';

interface VersionedDocumentBodyWithPermissionGroupId extends VersionedDocumentBody {
  ownerPermissionsGroup?: PermissionsGroupId;
}

type HistoryManagerStateType = VersionedDocumentBodyWithPermissionGroupId | PageLayoutResponseV2 | undefined;

function shouldSetNewHistoryState<T extends HistoryManagerStateType>(
  { originalState }: HistoryStateObject<T>,
  state: HistoryManagerStateType
): boolean {
  // This checks if we have a new entity by comparing IDs
  if (
    originalState === undefined ||
    state === undefined ||
    ((state as VersionedDocumentBody).entityId &&
      (originalState as VersionedDocumentBody).entityId !== (state as VersionedDocumentBody).entityId) ||
    ((state as PageLayoutResponseV2).id &&
      (originalState as PageLayoutResponseV2).id !== (state as PageLayoutResponseV2).id)
  ) {
    return true;
  }
  // This checks if we just saved a new version of the same entity
  const originalStateUpdated = new Date(originalState.updatedDate);
  const newStateUpdated = new Date(state.updatedDate);
  return newStateUpdated > originalStateUpdated;
}

export const testIds = {
  resetButton: (key: string): string => `history.reset-button-${key}`,
  undoButton: (key: string): string => `history.undo-button-${key}`,
  redoButton: (key: string): string => `history.redo-button-${key}`,
  saveButton: (key: string): string => `history.save-button-${key}`,
  publishButton: (key: string): string => `history.publish-button-${key}`
};

const useStyles = makeStyles()((theme) => ({
  buttonPanel: {
    display: 'flex',
    gap: theme.spacing(2),
    flexGrow: 1
  },
  button: {
    flexGrow: 1
  }
}));

type HistoryManagerId = 'categories' | 'channels' | 'collection' | 'hpc' | 'layout';

type HistoryStateObject<Entity extends HistoryManagerStateType> = {
  originalState: Entity | undefined;
  editHistory: Entity[];
};

interface HistoryManagerProps<Entity extends HistoryManagerStateType> {
  id: HistoryManagerId;
  state: Entity;
  onChange: (newState: Entity | ((oldState: Entity) => Entity)) => void;
  onCleanChange?: (isClean: boolean) => void;
  customButton?: React.ReactElement;
  saveCallback: () => Promise<unknown>;
  publishCallback?: () => Promise<unknown>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  skipHistory?: (newState: any) => boolean;
  hasUpsertPermission?: boolean;
  hasPublishPermission?: boolean;
  showRedoButton?: boolean;
}

function HistoryManager<Entity extends HistoryManagerStateType>({
  id,
  state,
  onChange,
  onCleanChange,
  customButton,
  saveCallback,
  publishCallback,
  skipHistory,
  hasUpsertPermission = true,
  hasPublishPermission = true,
  showRedoButton = true
}: HistoryManagerProps<Entity>): JSX.Element {
  const { classes } = useStyles();
  const { t } = useLocales();
  const [editHistoryState, setEditHistoryState] = useState<HistoryStateObject<Entity>>({
    originalState: undefined,
    editHistory: []
  });
  const [redoHistoryState, setRedoHistoryState] = useState<Entity[]>([]);
  const [, setHistoryManagerState] = useRecoilState(withHistoryManager);

  const [isClean, setIsClean] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);

  const canSave = !isClean && hasUpsertPermission;
  const canPublish =
    state &&
    isClean &&
    (state as VersionedDocumentBody).status !== VersionedDocumentStatus.PUBLISHED &&
    hasPublishPermission;

  usePrompt(!isClean && hasUpsertPermission);
  useNavigationGuard(isClean);

  useEffect(() => {
    if (!state) return;
    if (shouldSetNewHistoryState(editHistoryState, state)) {
      return setNewOriginalState();
    }
    if (shouldPushStateToHistory(state)) {
      return pushHistoryAction();
    }
  }, [state]);

  const { undo, redo, pushState, reset } = useGlobalHistoryManager(id, async (action) => {
    switch (action) {
      case 'undo':
        undoHistory();
        break;
      case 'redo':
        redoHistory();
        break;
      case 'save':
        if (canSave) {
          await onSave();
        }
        break;
      case 'publish':
        if (canPublish) {
          await onPublish();
        }
        break;
    }
  });

  useEffect(() => {
    onCleanChange && onCleanChange(isClean);
    setHistoryManagerState({
      isClean
    });
  }, [isClean]);

  const shouldPushStateToHistory = (state: HistoryManagerStateType): boolean => {
    const lastState = editHistoryState.editHistory.slice(-1)[0] || editHistoryState.originalState;
    return !skipHistory?.(state) && !isEqual(lastState, state);
  };

  const updateHistoryState = (historyState: HistoryStateObject<Entity>) => {
    setEditHistoryState(historyState);
    setIsClean(historyState.editHistory.length === 0);
  };

  const setNewOriginalState = () => {
    setRedoHistoryState([]);
    updateHistoryState({
      originalState: state,
      editHistory: []
    });
    reset();
  };

  const pushHistoryAction = () => {
    setRedoHistoryState([]);
    updateHistoryState({
      ...editHistoryState,
      editHistory: [...editHistoryState.editHistory, state as Entity]
    });
    pushState();
  };

  // Listeners

  function resetHistory() {
    onChange(editHistoryState.originalState as Entity);
    updateHistoryState({
      originalState: editHistoryState.originalState as Entity,
      editHistory: []
    });
    setRedoHistoryState([]);
    reset();
  }

  const undoHistory = () => {
    setRedoHistoryState([editHistoryState.editHistory[editHistoryState.editHistory.length - 1], ...redoHistoryState]);
    const newEditHistory = editHistoryState.editHistory.slice(0, -1);
    const newState = newEditHistory[newEditHistory.length - 1] || editHistoryState.originalState;

    onChange(newState as Entity);
    updateHistoryState({
      ...editHistoryState,
      editHistory: newEditHistory
    });
    undo();
  };

  const redoHistory = () => {
    if (!redoHistoryState.length) return;
    onChange(redoHistoryState[0]);
    updateHistoryState({
      ...editHistoryState,
      editHistory: [...editHistoryState.editHistory, redoHistoryState[0]]
    });
    setRedoHistoryState(redoHistoryState.slice(1));
    redo();
  };

  const onSave = async () => {
    setIsSaving(true);
    await saveCallback();
    setIsSaving(false);

    // Shouldn't need to do anything else, as long as the save hook updates the
    // monitored state with the response version, the useEffect should
    // pick up on the changed updatedDate and reset to an initial state.
  };

  const onPublish = async () => {
    if (!publishCallback) return;
    setIsPublishing(true);
    await publishCallback();
    setIsPublishing(false);
  };

  return (
    <div className={classes.buttonPanel}>
      <Button
        className={classes.button}
        size="small"
        color="grey"
        disabled={isClean || isSaving || isPublishing}
        onClick={resetHistory}
        data-testid={testIds.resetButton(id)}
      >
        {t('general.reset')}
      </Button>
      <Tooltip title={ctrlKeyString + 'Z'}>
        <div>
          <Button
            className={classes.button}
            size="small"
            color="grey"
            disabled={isClean || isSaving || isPublishing}
            onClick={undoHistory}
            data-testid={testIds.undoButton(id)}
          >
            {t('general.undo')}
          </Button>
        </div>
      </Tooltip>
      {showRedoButton && (
        <Tooltip title={ctrlKeyString + 'Y / ' + ctrlKeyString + '⇧' + 'Z'}>
          <div>
            <Button
              className={classes.button}
              size="small"
              color="grey"
              disabled={!redoHistoryState.length}
              onClick={redoHistory}
              data-testid={testIds.redoButton(id)}
            >
              {t('general.redo')}
            </Button>
          </div>
        </Tooltip>
      )}
      {customButton ? (
        React.cloneElement(customButton, {
          disabled: !canSave,
          onClick: onSave,
          loading: isSaving
        })
      ) : (
        <Tooltip title={ctrlKeyString + 'S'}>
          <div>
            <Button
              className={classes.button}
              color="primary"
              size="small"
              disabled={!canSave}
              onClick={onSave}
              loading={isSaving}
              data-testid={testIds.saveButton(id)}
            >
              {t('general.save')}
            </Button>
          </div>
        </Tooltip>
      )}
      {publishCallback && (
        <Tooltip title={ctrlKeyString + 'P'}>
          <div>
            <Button
              className={classes.button}
              color="primary"
              size="small"
              disabled={!canPublish}
              onClick={onPublish}
              loading={isPublishing}
              data-testid={testIds.publishButton(id)}
            >
              {t('general.publish')}
            </Button>
          </div>
        </Tooltip>
      )}
    </div>
  );
}

export default HistoryManager;
