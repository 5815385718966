import React, { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import CollectionsPanel from '../Collections/CollectionsPanel';
import CollectionForm from '../Collections/CollectionForm';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import { ContentCollectionType, SmartQueryFieldContentType } from '../../API';
import { useNavigate, useParams } from 'react-router-dom';
import { useData } from '../../data-layer';
import { CollectionsPanelTabViewOptions } from '../../data-layer';
import { testIds as expandTestIds } from '../Collections/CollectionExpand';
import { AppRoutes } from '../../Routes';
import CollectionLayoutsModal from '../Collections/CollectionsPanel/CollectionsBrowser/CollectionLayoutsModal';
import { Allotment } from 'allotment';
import { useSplitPane } from '../../hooks/General/useSplitPane';

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex'
  }
}));

export const testIds = {
  root: 'collections.root',
  expandButton: expandTestIds.expandButton,
  closeIcon: 'collections.expand-button.close-icon',
  openIcon: 'collections.expand-button.open-icon'
};

function MetadataManager(): JSX.Element {
  const {
    collections: {
      state: {
        withCollectionsExpanded,
        withCollectionContentTypes,
        withCollectionQueryFields,
        withCollectionQueryOperators,
        withCollectionQuerySortings,
        withCollectionsPanelTabView,
        withSelected,
        withAllGenres,
        withSplitPaneSizes
      },
      hook: { getById, getSmartQueryParameters, setSelected, getGenres }
    },
    media: {
      state: { withContentTypesAllowed }
    }
  } = useData();

  const { classes } = useStyles();
  const setCollectionContentTypes = useSetRecoilState(withCollectionContentTypes);
  const expanded = useRecoilValue(withCollectionsExpanded);
  const setWhichView = useSetRecoilState(withCollectionsPanelTabView);
  const setQuerySortings = useSetRecoilState(withCollectionQuerySortings);
  const setQueryOperators = useSetRecoilState(withCollectionQueryOperators);
  const setQueryFields = useSetRecoilState(withCollectionQueryFields);
  const [allGenres, setAllGenres] = useRecoilState(withAllGenres);
  const [, setContentTypesAllowed] = useRecoilState(withContentTypesAllowed);
  const navigate = useNavigate();
  const { id: collectionId } = useParams<{ id: string | undefined }>();

  const { sizes, onChange: onSplitPaneChange } = useSplitPane(withSplitPaneSizes);

  const selectedCollection = useRecoilValue(withSelected);

  const getAllGenres = async () => {
    if (!allGenres?.length) {
      const genres = await getGenres();
      setAllGenres(genres);
    }
  };

  useEffect(() => {
    const { MOVIE, SERIES, EPISODE } = SmartQueryFieldContentType;
    const usedCollectionContentTypes = [MOVIE, SERIES, EPISODE];
    setCollectionContentTypes(usedCollectionContentTypes);
    getCollectionParams(usedCollectionContentTypes);
    setSelected(undefined);
    getAllGenres();
    setContentTypesAllowed([ContentCollectionType.SERIES]);
  }, []);

  useEffect(() => {
    if (!collectionId) {
      setSelected(undefined);
      return;
    }
    fetchAndSetCollection(collectionId);
  }, [collectionId]);

  useEffect(() => {
    if (!expanded) {
      setWhichView(CollectionsPanelTabViewOptions.CONTENT_SEARCH);
    }
  }, [expanded]);

  const fetchAndSetCollection = async (collectionId: string) => {
    try {
      const foundCollection = await getById(collectionId, true);
      if (foundCollection) {
        setSelected(foundCollection.entityId);
        return;
      }
      navigate(AppRoutes.collections, { replace: true });
    } catch (err) {
      navigate(AppRoutes[404], { replace: true });
    }
  };

  const getCollectionParams = async (contentTypes: SmartQueryFieldContentType[]) => {
    const data = await getSmartQueryParameters(contentTypes);
    if (data) {
      setQuerySortings(data.sortings);
      setQueryOperators(data.operators);
      setQueryFields(data.fields);
    }
  };

  return (
    <>
      <div data-testid={testIds.root} className={classes.root}>
        <Allotment minSize={580} defaultSizes={sizes} onChange={onSplitPaneChange}>
          <Allotment.Pane>
            <CollectionsPanel
              forKids={selectedCollection?.kids}
              options={{
                tabs: [CollectionsPanelTabViewOptions.CONTENT_SEARCH]
              }}
            />
          </Allotment.Pane>
        </Allotment>
      </div>
      <CollectionForm />
      <CollectionLayoutsModal />
    </>
  );
}

export default MetadataManager;
