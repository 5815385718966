import { UIModuleType } from '../layouts';

/**
 * Mapping of UIModuleType to the localized field path in the CMS
 */
export const UI_MODULES_LOCALIZED_FIELDS: Record<UIModuleType, string> = {
  ACCOUNT_CREATION: 'layouts.account_creation.fields',
  ACCOUNT_LOADING: 'layouts.account_loading.fields',
  ACCOUNT_SUCCESS: 'layouts.account_success.fields',
  AI_SEARCH: 'layouts.ai_search.fields',
  BECAUSE_YOU: '',
  COLLECTION_LIST: '',
  COLLECTION_SPLASH_HERO: '',
  CONTENT_LIST: '',
  CONTINUE_WATCHING: '',
  COPY: '',
  HERO: '',
  INLINE_PAGE: 'layouts.inline_page.fields',
  INLINE_PROMO: '',
  LANDING_PROMO: 'layouts.landing_promo.fields',
  LINKING_ACCOUNT: 'layouts.linking_account.fields',
  LIVE_NEWS: '',
  LIVE_PLUS: 'layouts.live_plus.fields',
  MENU_ITEM: '',
  MENU_SECTION: '',
  MIXED_LIST: '',
  NEW_PARTNER_LANDING: 'layouts.new_partner_landing.fields',
  ONE_BRAND_PROMO: 'layouts.one_brand_promo.fields',
  PAGE_LIST: '',
  PLAN_SUMMARY: 'layouts.plan_summary.fields',
  PLAYBACK_PAYWALL: 'layouts.playback_paywall.fields',
  PROFILE: 'layouts.profile.fields',
  PROMOTION: 'layouts.promotion.fields',
  RECOMMENDATIONS: '',
  REGISTRATION_WALL: 'layouts.reg_wall.fields',
  SPIN_PREMIA: 'layouts.spin_premia.fields',
  SPORTS_EVENT: '',
  SPORTS_HERO: '',
  TDS_GIFT_CARDS: 'layouts.tds_gift_cards.fields',
  TOURNAMENT_LIST: '',
  TOURNAMENT_SPLASH_HERO: '',
  WATCH_LIST: '',
  WHO_IS_WATCHING: 'layouts.who_is_watching.fields'
};

export function getLocalizedModuleField(moduleType?: UIModuleType): string {
  return moduleType ? UI_MODULES_LOCALIZED_FIELDS[moduleType] : '';
}
