import React, { useState } from 'react';
import { Edit, Warning } from '@mui/icons-material';
import Delete from '@mui/icons-material/Delete';
import { alpha, Checkbox, Chip, Tooltip, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { isFunction } from 'lodash-es';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  MenuActionType,
  MenuItemUiModuleBody,
  MenuItemUiModuleBodyModuleTypeEnum,
  MenuSectionUiModuleBody,
  MenuSectionUiModuleBodyModuleTypeEnum
} from '../../../API';
import { useLocales, useTheme } from '../../../hooks';
import {
  UiModuleBody,
  withCheckedModuleIds,
  withLayoutFormMetadata,
  withSelectedLayout,
  withSelectedLayoutUIModules
} from '../../../state/Layouts';
import IconButton from '../../shared/IconButton';
import ObjectPreview from '../../shared/ObjectPreview';
import { PreviewLink } from './ContentPreview/PreviewLink';
import { EntitlementsVisibilityBadge } from '../../shared/Entitlements';
import { PlatformBadge } from '../../shared/Platforms/PlatformBadge';
import { usePermissions } from '../../../hooks/Permissions/usePermissions';
import { Stack } from '@mui/system';
import { UIModuleListItemDivider } from './UIModuleListItemDivider';
import { withDndActive } from '../../shared/Sortable2';
import { UIModuleListItemMarker } from './UIModuleListItemMarker';
import { stopPointerPropagation } from '../../../utils/generalUtils';
import { ExperimentsBadge } from '../../shared/ExperimentsBadge';
import { withStatsigUiModulesDefaultConfig, withUiModulesConfigByType } from '../../../state/Experiments';
import { UiModules } from '../../../utils/layouts';
import UIModuleExport from '../UIModuleExport';

type StyleProps = {
  isEditable: boolean;
  isMenuItem: boolean;
  isNestedMenuItem: boolean;
};

const useStyles = makeStyles<StyleProps>()((theme, { isEditable, isMenuItem, isNestedMenuItem }) => ({
  listItemContainer: {
    display: 'block',
    borderTop: isEditable ? `1px dotted ${theme.palette.divider}` : 'unset',
    borderBottom: `1px dotted ${theme.palette.divider}`,
    borderLeft: isNestedMenuItem ? `1px dotted ${theme.palette.divider}` : 'unset',
    marginBottom: -1
  },
  // Since we are using react-virtuoso, we need to render all the items, even if they are hidden
  // Otherwise, react-virtuoso will not calculate the correct height for the items and will trigger an error
  hidden: {
    visibility: 'hidden',
    height: 0.5,
    overflow: 'hidden'
  },
  listItemMain: {
    padding: isEditable && !isMenuItem ? theme.spacing(1, 4, 1, 1) : theme.spacing(2, 4, 2, 1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': {
      background: alpha(theme.palette.background.paper, 0.5)
    }
  },
  leftSide: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(4),
    flexGrow: 1,
    overflowX: 'hidden'
  },
  leftSideInner: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: theme.spacing(2)
  },
  rightButtons: {
    display: 'flex',
    whiteSpace: 'nowrap'
  },
  topLine: {
    alignItems: 'center',
    justifyContent: 'space-between',
    '&, & > div': {
      display: 'flex',
      gap: theme.spacing(2, 4)
    }
  },
  option: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center'
  },
  warningIcon: {
    color: `${theme.palette.warning.main} !important`
  }
}));

export const testIds = {
  componentRoot: 'umli.component-root',
  editButton: 'umli.edit-button',
  deleteButton: 'umli.delete-button',
  topLineTitle: 'umli.top-line-title',
  emptyUIModuleListItem: 'umli.empty-ui-module-list-item'
};

export interface IBaseUIModuleListItemProps<T = UiModuleBody> {
  uiModule: T;
  index: number;
  isEditable: boolean;
  hidden?: boolean;
}

export interface IUIModuleListItemProps extends IBaseUIModuleListItemProps {
  menuSectionIndex?: number;
  topLineTitle: React.ReactNode;
  topLine?: React.ReactNode[];
  collectionLine?: React.ReactNode[];
  renderPreview?: (showing: boolean) => React.ReactNode;
}

function UIModuleListItem({
  uiModule,
  index,
  isEditable,
  menuSectionIndex = -1,
  topLineTitle,
  topLine,
  collectionLine,
  renderPreview,
  hidden = false
}: IUIModuleListItemProps): JSX.Element {
  const isMenuItem =
    uiModule.moduleType === MenuSectionUiModuleBodyModuleTypeEnum.MENU_SECTION ||
    uiModule.moduleType === MenuItemUiModuleBodyModuleTypeEnum.MENU_ITEM;
  const isNestedMenuItem = menuSectionIndex >= 0;
  const { classes } = useStyles({ isEditable, isMenuItem, isNestedMenuItem });
  const { t } = useLocales();
  const { formControlColor } = useTheme();

  const setFormMetadata = useSetRecoilState(withLayoutFormMetadata);
  const [uiModules, setUIModules] = useRecoilState(withSelectedLayoutUIModules);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const selectedlayout = useRecoilValue(withSelectedLayout);
  const [checkedModuleIds, setCheckedModuleIds] = useRecoilState(withCheckedModuleIds);
  const { hasPermissions } = usePermissions();
  const { UPSERT: hasUpsertPermission, DELETE: hasDeletePermission } = hasPermissions(
    selectedlayout?.ownerPermissionsGroup
  );
  const isDndActive = useRecoilValue(withDndActive);

  const uiModulesDefaultConfig = useRecoilValue(withStatsigUiModulesDefaultConfig);
  const uiModuleConfig = useRecoilValue(withUiModulesConfigByType(uiModule.moduleType));
  const canUseExperiments = uiModuleConfig?.areExperimentsActive ?? uiModulesDefaultConfig?.areExperimentsActive;
  const isModuleActive = !!uiModuleConfig?.isEnabled;

  const hasPreview = isFunction(renderPreview);
  const parentMenuSection =
    uiModules && isNestedMenuItem ? (uiModules[menuSectionIndex] as MenuSectionUiModuleBody) : undefined;

  const deleteUIModule = (index: number) => {
    if (!uiModules) return;
    if (isNestedMenuItem && parentMenuSection) {
      const menuItems = parentMenuSection.menuItems
        .slice(0, index)
        .concat(parentMenuSection.menuItems.slice(index + 1));
      const modifiedModule = menuItems.length
        ? ({
            ...parentMenuSection,
            menuItems
          } as MenuSectionUiModuleBody)
        : ({
            ...parentMenuSection,
            menuItems: undefined,
            menuActionType: MenuActionType.PAGE,
            moduleType: MenuItemUiModuleBodyModuleTypeEnum.MENU_ITEM
          } as MenuItemUiModuleBody);
      setUIModules(
        uiModules
          .slice(0, menuSectionIndex)
          .concat(modifiedModule)
          .concat(uiModules.slice(menuSectionIndex + 1))
      );
    } else {
      setUIModules(uiModules.slice(0, index).concat(uiModules.slice(index + 1)));
    }
  };

  const handleEdit = () => {
    const moduleType = uiModule.moduleType;

    let uiModuleToUpdate = uiModule;

    const setDynamicModuleFields = UiModules[moduleType]?.setDynamicModuleFields;

    if (setDynamicModuleFields) {
      // Set dynamic fields to the module that's being edited (for retro-compatibility)
      uiModuleToUpdate = setDynamicModuleFields(uiModuleToUpdate);
    }

    setFormMetadata((state) => ({
      ...state,
      record: uiModuleToUpdate,
      isShowingForm: true,
      isNew: false,
      isEditing: true,
      index: isNestedMenuItem ? [menuSectionIndex, index] : index
    }));
  };

  const onCheckboxChange = (evt: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (!uiModule.moduleId) return;
    const newCheckedModuleIds = { ...checkedModuleIds };
    if (checked) {
      newCheckedModuleIds[uiModule.moduleId] = true;
    } else {
      delete newCheckedModuleIds[uiModule.moduleId];
    }
    setCheckedModuleIds(newCheckedModuleIds);
  };

  const ModuleTypeChip = () => {
    const label = t(`layouts.ui_module_${uiModule.moduleType.toLowerCase()}`);
    if (isModuleActive) return <Chip label={label} size="small" />;
    return (
      <Tooltip arrow placement="top" title={t('layouts.module_not_active')}>
        <Chip label={label} size="small" avatar={<Warning className={classes.warningIcon} />} />
      </Tooltip>
    );
  };

  return hidden ? (
    <div data-testid={testIds.emptyUIModuleListItem} className={classes.hidden}></div>
  ) : (
    <div
      data-testid={testIds.componentRoot}
      className={classes.listItemContainer}
      data-index={index}
      data-module-type={uiModule.moduleType}
    >
      {isEditable && !isMenuItem && <UIModuleListItemDivider index={index} />}
      <div className={classes.listItemMain}>
        <div className={classes.leftSide}>
          <Checkbox
            color={formControlColor}
            disabled={isNestedMenuItem}
            checked={!!checkedModuleIds[parentMenuSection?.moduleId || String(uiModule.moduleId)]}
            onChange={onCheckboxChange}
          />
          <div className={classes.leftSideInner}>
            <div className={classes.topLine}>
              <div style={{ flexWrap: 'wrap' }}>
                <UIModuleListItemMarker>
                  <Typography variant="h6" data-testid={testIds.topLineTitle}>
                    {topLineTitle}
                  </Typography>
                </UIModuleListItemMarker>
                {uiModule.moduleType && (
                  <div className={classes.option}>
                    <Typography variant="body2" color="textSecondary">
                      {t('layouts.type')}:
                    </Typography>
                    <UIModuleListItemMarker>
                      <ModuleTypeChip />
                    </UIModuleListItemMarker>
                  </div>
                )}
                {topLine?.map((option, i) => (
                  <div key={i} className={classes.option}>
                    {option}
                  </div>
                ))}
                {!!uiModule.experiments?.length && (
                  <div className={classes.option}>
                    <ExperimentsBadge
                      moduleExperiments={uiModule.experiments}
                      moduleCanUseExperiments={canUseExperiments}
                    />
                  </div>
                )}
              </div>
            </div>
            <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" gap={4}>
              {hasPreview && <PreviewLink isShowingPreview={showPreview} setIsShowingPreview={setShowPreview} />}
              {collectionLine?.map((option, i) => (
                <div key={i} className={classes.option}>
                  {option}
                </div>
              ))}
            </Stack>
          </div>
        </div>
        <Stack gap={2}>
          <div>{uiModule.entitlements && <EntitlementsVisibilityBadge entitlements={uiModule.entitlements} />}</div>
          <div>{uiModule.targetPlatform && <PlatformBadge platforms={uiModule.targetPlatform} />}</div>
        </Stack>
        <div className={classes.rightButtons}>
          {isEditable && (
            <>
              <IconButton
                data-testid={testIds.editButton}
                size="large"
                onClick={() => handleEdit()}
                title={t('layouts.edit_ui_module')}
                disabled={!hasUpsertPermission}
              >
                <Edit fontSize="small" />
              </IconButton>
              <IconButton
                data-testid={testIds.deleteButton}
                size="large"
                onClick={() => deleteUIModule(index)}
                title={t('layouts.delete_ui_module')}
                disabled={!hasDeletePermission}
              >
                <Delete fontSize="small" />
              </IconButton>
            </>
          )}
          <div {...stopPointerPropagation}>
            <ObjectPreview object={uiModule} title={t('layouts.ui_module')} />
          </div>
          {uiModule.moduleId && <UIModuleExport moduleId={uiModule.moduleId} moduleType={uiModule.moduleType} />}
        </div>
      </div>
      <div style={{ cursor: 'initial' }} {...stopPointerPropagation}>
        {renderPreview?.(!isDndActive && showPreview)}
      </div>
    </div>
  );
}

export default UIModuleListItem;
