import { useSetRecoilState } from 'recoil';
import { GetAllMediaQuery, MediaExportQuery, MediaResponse, SmartQuerySearchIndex, SmartQueryBody } from '../../../API';
import { Paginated, useLocales } from '../../../hooks';
import { MediaAPI } from '../../../hooks/API/Media/MediaAPI';
import { useNotificationSubscription } from '../../../hooks/API/NotificationSubscription/useNotificationSubscription';
import { DataManagerHook, DataManagerHookReturnType, IDataManagerHookProps } from '../../DataManager';
import { CacheManager } from '../../DataManager/CacheManager';
import { DataManagerHelpers } from '../../DataManager/DataManagerHelpers';
import { saveAsFile } from '../../../utils/saveAsFile';

export interface MediaHookReturnType extends DataManagerHookReturnType<MediaResponse> {
  searchMedia: (params: GetAllMediaQuery) => Promise<Paginated<MediaResponse> | undefined>;
  exportMediaToCSV: (query: MediaExportQuery) => Promise<void>;
  getMediaByCollectionId: (collectionId: string) => Promise<MediaResponse[] | undefined>;
  getMediaBySmartQuery: (query: SmartQueryBody) => Promise<MediaResponse[] | undefined>;
  getContentSubscriptionStatus: (contentId: string) => Promise<boolean | undefined>;
  setContentSubscriptionStatus: (contentId: string, status: boolean) => Promise<boolean>;
}

export function MediaHook(params: IDataManagerHookProps<MediaResponse>): MediaHookReturnType {
  const dataManagerHook = DataManagerHook<MediaResponse>(params);
  const api = params.useApiHook() as MediaAPI;
  const notificationSubscriptionApi = useNotificationSubscription();

  const setCache = useSetRecoilState(params.state.withDataCache);
  const { addRecordsToCache } = CacheManager(params.idField, setCache);

  const { t } = useLocales();
  const { handleApiError, missingActionError } = DataManagerHelpers({ name: params.name, toString });

  const searchMedia = async (params: GetAllMediaQuery) => {
    const action = 'fetch_paginated';
    try {
      if (!dataManagerHook.fetchPaginatedPost) throw missingActionError(action);
      return await dataManagerHook.fetchPaginatedPost(params);
    } catch (err) {
      console.error(err);
      handleApiError(err, 'get_paginated', JSON.stringify(params));
    }
  };

  const exportMediaToCSV = async (query: MediaExportQuery) => {
    try {
      const response = await api.exportCsv(query);
      saveAsFile(response);
    } catch (err) {
      console.error(err);
      handleApiError(err, 'export_media_to_csv', `${t('query_builder.query')}: ${query}`);
    }
  };

  const getMediaByCollectionId = async (collectionId: string) => {
    try {
      const response = await api.getByCollectionId(collectionId);
      addRecordsToCache(response.data.body);
      return response.data.body;
    } catch (err) {
      console.error(err);
      handleApiError(err, 'get_media_by_collection_id', collectionId);
    }
  };

  const getMediaBySmartQuery = async (query: SmartQueryBody) => {
    try {
      const fetchedMedia = query
        ? (await api.getBySmartQuery({ ...query, searchIndex: SmartQuerySearchIndex.CONTENT })).data.body
        : [];
      addRecordsToCache(fetchedMedia);
      return fetchedMedia;
    } catch (err) {
      console.error(err);
      handleApiError(err, 'get_by_smart_query', `${t('query_builder.query')}: ${query}`);
    }
  };

  const getContentSubscriptionStatus = async (contentId: string) => {
    try {
      const { data } = await notificationSubscriptionApi.getById(contentId);
      return data.body.isEnabled;
    } catch (err) {
      console.error(err);
      handleApiError(err, 'get_content_subscription_status', contentId);
    }
  };

  const setContentSubscriptionStatus = async (contentId: string, isEnabled: boolean) => {
    try {
      if (isEnabled) {
        await notificationSubscriptionApi.create(contentId);
      } else {
        await notificationSubscriptionApi.remove(contentId);
      }
      return true;
    } catch (err) {
      console.error(err);
      handleApiError(err, 'set_content_subscription_status', contentId);
      return false;
    }
  };

  return {
    ...dataManagerHook,
    searchMedia,
    exportMediaToCSV,
    getMediaByCollectionId,
    getMediaBySmartQuery,
    getContentSubscriptionStatus,
    setContentSubscriptionStatus
  };
}
