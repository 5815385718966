import {
  ContinueWatchingUiModuleBody,
  ContinueWatchingUiModuleBodyModuleTypeEnum,
  ContinueWatchingUIModuleContentTreatment,
  DocumentLocale
} from '../../../API';
import generateId from '../../../utils/generateId';
import { NewModuleFunction } from '../../../utils/layouts';

export const blankContinueWatchingModule: NewModuleFunction<ContinueWatchingUiModuleBody> = (module) => ({
  title: {
    [DocumentLocale.ES]: ''
  },
  moduleType: ContinueWatchingUiModuleBodyModuleTypeEnum.CONTINUE_WATCHING,
  contentTreatment: ContinueWatchingUIModuleContentTreatment.PORTRAIT,
  moduleId: generateId(),
  ...module
});
