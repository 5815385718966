import React, { useMemo } from 'react';
import { AddOutlined, Check, Delete, DragHandle, Edit, Link, PublicOff, Search } from '@mui/icons-material';
import { alpha, Avatar, Box, emphasize, Typography, typographyClasses } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { difference } from 'lodash-es';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  CategoryChannel,
  McpChannelResponse,
  EntitlementType,
  VersionedDocumentStatus,
  VllChannelResponse
} from '../../../API';
import { useData } from '../../../data-layer';
import { useLocales } from '../../../hooks';
import AssetImage from '../../shared/AssetImage';
import Button from '../../shared/Button';
import DraftBadge from '../../shared/DraftBadge';
import IconButton from '../../shared/IconButton';
import Localized from '../../shared/Localized';
import TextClamp from '../../shared/TextClamp';
import PremiumBadge from '../../shared/PremiumBadge';
import { EntitlementsMultiSelectLite } from '../../shared/Entitlements/EntitlementsMultiSelectLite';
import { themeColors } from '../../../theme';
import { DisplayAsOptions } from '../../../utils/types/genericTypes';
import EPGChannelImage from './EPGChannelImage';
import { LabelClass } from '../../Collections/CollectionsPanel/Media/License';
import { getMediaAspectRatio } from '../../../utils/styleUtils';
import StyledPremiumBadge from '../../shared/StyledPremiumBadge';

const TitleClass = 'title';

type StyleProps = {
  isStandalone: boolean;
  displayAs?: DisplayAsOptions;
};

type StyleClasses = 'collapsed';

export interface IEPGChannelItemProps {
  channel: VllChannelResponse | McpChannelResponse;
  categoryChannel?: CategoryChannel;
  onCategoryChannelChanged?: (categoryChannel: CategoryChannel) => void;
  isContentServiceChannel?: boolean;
  isChannelPicker?: boolean;
  isStandalone?: boolean;
  blocked?: boolean;
  onChannelPicked?: (channel: VllChannelResponse) => void;
  highlighted?: boolean;
  'data-testid'?: string;
  'data-channel-id'?: string;
  className?: string;
}

export const GAP_SIZE = 2;

const useStyles = makeStyles<StyleProps, StyleClasses>()((theme, { isStandalone, displayAs }) => ({
  channelsListItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: isStandalone ? undefined : theme.spacing(1, 1, 1, 2),
    borderBottom: isStandalone ? undefined : `1px solid ${theme.palette.divider}`,
    position: 'relative',
    minHeight: isStandalone ? 44 : 55,
    gap: theme.spacing(GAP_SIZE),
    '&.sortableFiltered': {
      opacity: 0.5,
      filter: 'grayscale(1)',
      cursor: 'default'
    }
  },
  channelImageItem: {
    position: 'relative',
    background: emphasize(theme.palette.background.paper, 0.1),
    fontSize: '0.9rem',
    userSelect: 'none',
    borderRadius: theme.shape.borderRadius,
    aspectRatio: getMediaAspectRatio(displayAs),
    overflow: 'hidden',
    '&:hover': {
      '& > div.title': {
        display: 'unset'
      }
    },
    '&.sortableFiltered': {
      opacity: 0.5,
      filter: 'grayscale(1)',
      cursor: 'default'
    }
  },
  channelPicker: {
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.divider
    }
  },
  channelsListItemInner: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(GAP_SIZE)
  },
  leftActionButton: {
    minWidth: 0,
    flexShrink: 0,
    padding: theme.spacing(1)
  },
  dragHandle: {
    cursor: 'grab'
  },
  listItemTopLine: {
    display: 'flex',
    gap: theme.spacing(0, GAP_SIZE),
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  channelNumberBadge: {
    background: theme.palette.divider
  },
  liveBadge: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 30,
    width: 30
  },
  channelNumberOverlayBadge: {
    height: 'auto'
  },
  logo: {
    filter: theme.palette.mode === 'light' ? 'invert(100%) grayscale(100%)' : 'none'
  },
  channelNumberText: {
    color: theme.palette.text.primary
  },
  img: {
    height: 30,
    width: 30
  },
  line: {
    height: 2,
    width: 30,
    backgroundColor: theme.palette.text.primary,
    position: 'absolute',
    inset: 0,
    margin: 'auto',
    transform: 'rotate(45deg)'
  },
  entitlements: {
    marginLeft: theme.spacing(2)
  },
  spotlightButton: {
    color: themeColors.orange,
    background: alpha(themeColors.orange, theme.palette.mode === 'light' ? 0.08 : 0.16)
  },
  highlighted: {
    boxShadow: `inset 0 0 2px 2px ${themeColors.orange}`,
    background: alpha(themeColors.orange, theme.palette.mode === 'light' ? 0.08 : 0.16)
  },
  blocked: {
    [`.${typographyClasses.root}`]: {
      opacity: 0.5
    }
  },
  titleOverlay: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    maxHeight: '100%',
    whiteSpace: 'break-spaces'
  },
  collapsed: {
    display: 'none',
    background: 'rgb(0 0 0 / 70%)',
    backdropFilter: 'blur(2px)',
    color: 'white'
  },
  hoverOverlay: {
    position: 'absolute',
    inset: 0,
    [`& > .${LabelClass}`]: {
      display: 'none'
    },
    ['&:hover']: {
      [`& > .${TitleClass}`]: {
        display: 'unset'
      }
    }
  }
}));

export const testIds = {
  addChannelButton: 'channels.add-channel-button',
  linkChannelButton: 'channels.link-channel-button',
  editChannelButton: 'channels.edit-channel-button',
  removeChannelButton: 'channels.remove-channel-button',
  channelSpotlightButton: 'channels.channel-spotlight-button',
  channelTitle: 'channels.channel-title',
  channelDescription: 'channels.channel-description',
  channelEditorNotes: 'channels.channel-editor-notes',
  channelNumber: 'channels.channel-channel-number',
  draftBadge: 'channels.draft-badge',
  channelPremiumBadge: 'channels.premium-badge',
  imageTitle: 'channels.image-title'
};

function EPGChannelItem({
  channel,
  isStandalone,
  categoryChannel,
  isContentServiceChannel,
  isChannelPicker,
  onChannelPicked,
  highlighted,
  className,
  blocked = false,
  ...props
}: IEPGChannelItemProps): JSX.Element {
  const {
    channels: {
      state: { withSpotlightChannelId },
      hook: { edit: editChannel, new: newChannel }
    },
    categories: {
      state: { withSelected: withSelectedCategory }
    },
    categoryBundles: {
      state: { withSelected: withSelectedCategoryBundle }
    },
    collections: {
      state: { withDisplayAs, withMixedCollectionDisplay }
    }
  } = useData();

  const displayAs = useRecoilValue(withDisplayAs);
  const asMixedType = useRecoilValue(withMixedCollectionDisplay);

  const { classes, cx } = useStyles({ isStandalone: !!isStandalone, displayAs });
  const { t, localize } = useLocales();

  const [selectedCategory, setSelectedCategory] = useRecoilState(withSelectedCategory);
  const selectedCategoryBundle = useRecoilValue(withSelectedCategoryBundle);
  const selectedCategoryChannels = selectedCategory?.categoryChannels;
  const { logo, status, isLive, channelNumber } = channel as VllChannelResponse;
  const isCategoryChannel = !!categoryChannel;
  const [spotlightChannelId, setSpotlightChannelId] = useRecoilState(withSpotlightChannelId);
  const isList = displayAs === DisplayAsOptions.LIST;

  const addChannel = (): void => {
    if (!selectedCategory) return;
    setSelectedCategory({
      ...selectedCategory,
      categoryChannels: [
        ...selectedCategory.categoryChannels,
        {
          channel: (channel as VllChannelResponse).entityId,
          entitlements: Object.values(EntitlementType) // Default to ALL entitlements
        }
      ]
    });
  };

  const onEntitlementsChanged = (entitlements: EntitlementType[]) => {
    if (!selectedCategory || !categoryChannel) return;
    const categoryChannels = [...selectedCategory.categoryChannels];
    const index = categoryChannels.findIndex((catChan) => catChan.channel === categoryChannel.channel);
    categoryChannels[index] = {
      ...categoryChannels[index],
      entitlements
    };
    setSelectedCategory({
      ...selectedCategory,
      categoryChannels
    });
  };

  const linkChannel = (): void => {
    newChannel(channel as McpChannelResponse);
  };

  const removeChannel = (): void => {
    if (!selectedCategory) return;
    setSelectedCategory({
      ...selectedCategory,
      categoryChannels: selectedCategory.categoryChannels.filter(
        (categoryChannel) => categoryChannel.channel !== (channel as VllChannelResponse).entityId
      )
    });
  };

  const handleClick = (): void => {
    if (onChannelPicked) {
      onChannelPicked(channel as VllChannelResponse);
    }
  };

  const handleEdit = (): void => {
    editChannel((channel as VllChannelResponse).entityId);
  };

  const handleSpotlightClick = (): void => {
    setSpotlightChannelId(isSpotlightChannel() ? undefined : (channel as VllChannelResponse).entityId);
  };

  const isSpotlightChannel = (): boolean => {
    return spotlightChannelId === (channel as VllChannelResponse).entityId;
  };

  const isInSelectedCategory = useMemo(() => {
    if (!selectedCategoryChannels) return false;
    return (
      selectedCategoryChannels.findIndex(
        (categoryChannel) => categoryChannel.channel === (channel as VllChannelResponse).entityId
      ) >= 0
    );
  }, [selectedCategoryChannels]);

  const channelException =
    (isCategoryChannel &&
      !isContentServiceChannel &&
      selectedCategoryBundle?.countries &&
      (channel as VllChannelResponse).specifications?.find(
        ({ countryCodes }) => !difference(selectedCategoryBundle.countries, countryCodes).length
      )) ||
    undefined;
  const channelTitle = (localize(channelException?.title) && channelException?.title) || channel.title;
  const channelLogo = (localize(channelException?.logo) && channelException?.logo) || logo;

  const classItem = () => {
    if (!isList && asMixedType) {
      return cx(classes.channelImageItem, className);
    }
    return cx(classes.channelsListItem, className, {
      [classes.channelPicker]: isChannelPicker,
      [classes.highlighted]: highlighted,
      [classes.blocked]: blocked,
      sortableFiltered: !isCategoryChannel && (blocked || isInSelectedCategory)
    });
  };

  return (
    <div
      className={classItem()}
      onClick={handleClick}
      data-channel-id={props['data-channel-id']}
      data-testid={props['data-testid']}
    >
      {!isList && asMixedType ? (
        <>
          <Box>
            <EPGChannelImage channelLogo={channelLogo} displayAs={displayAs} />
          </Box>
          <div className={classes.hoverOverlay}>
            <Box className={cx(TitleClass, classes.titleOverlay, classes.collapsed)} data-testid={testIds.imageTitle}>
              <Avatar className={classes.channelNumberOverlayBadge} variant="rounded">
                <Typography
                  className={cx({ [classes.channelNumberText]: !!channelNumber })}
                  component="div"
                  variant="body2"
                  data-testid={testIds.channelNumber}
                >
                  {channelNumber || <span className={classes.line}></span>}
                </Typography>
              </Avatar>
              {localize(channelTitle)}
            </Box>
          </div>
          {isLive && <img className={classes.liveBadge} src="/assets/live.png" />}
          {channel?.isPremium && <StyledPremiumBadge />}
        </>
      ) : (
        // TODO: Get all this section into a separate component
        <>
          <div className={classes.channelsListItemInner}>
            {!isCategoryChannel &&
              !isChannelPicker &&
              !isStandalone &&
              (isContentServiceChannel ? (
                <Button
                  className={classes.leftActionButton}
                  variant="contained"
                  disableElevation
                  color="grey"
                  onClick={linkChannel}
                  data-testid={testIds.linkChannelButton}
                >
                  <Link />
                </Button>
              ) : (
                <Button
                  className={classes.leftActionButton}
                  variant={blocked ? 'text' : 'contained'}
                  disableElevation
                  disabled={!selectedCategoryChannels || isInSelectedCategory || blocked}
                  color="grey"
                  onClick={addChannel}
                  data-testid={testIds.addChannelButton}
                >
                  {blocked ? <PublicOff /> : isInSelectedCategory ? <Check /> : <AddOutlined />}
                </Button>
              ))}
            {isCategoryChannel && <DragHandle className={classes.dragHandle} />}
            <Avatar className={classes.channelNumberBadge} variant="rounded">
              <Typography
                className={cx({ [classes.channelNumberText]: !!channelNumber })}
                component="div"
                variant="body2"
                data-testid={testIds.channelNumber}
              >
                {channelNumber || <span className={classes.line}></span>}
              </Typography>
            </Avatar>
            {channelLogo && <AssetImage path={channelLogo} className={classes.logo} />}
            <div>
              <Typography
                className={classes.listItemTopLine}
                component="div"
                variant="body1"
                data-testid={testIds.channelTitle}
              >
                <Localized prop={channelTitle} />
                {status === VersionedDocumentStatus.DRAFT && <DraftBadge data-testid={testIds.draftBadge} />}
                {channel.isPremium && <PremiumBadge height={20} />}
              </Typography>
              <TextClamp lines={isStandalone ? 1 : 4} hideShowMore={isStandalone}>
                <Typography
                  component="div"
                  variant="body2"
                  color="textSecondary"
                  data-testid={channel.editorNotes ? testIds.channelEditorNotes : testIds.channelDescription}
                >
                  {channel.editorNotes
                    ? channel.editorNotes
                    : channel.description && <Localized prop={channel.description} />}
                </Typography>
              </TextClamp>
            </div>
          </div>
          <div className={classes.channelsListItemInner}>
            {isLive && <img className={classes.img} src="/assets/live.png" />}
            {categoryChannel && (
              <EntitlementsMultiSelectLite
                className={classes.entitlements}
                value={categoryChannel.entitlements}
                onChange={onEntitlementsChanged}
                size="small"
              />
            )}
            {!isCategoryChannel && !isContentServiceChannel && !blocked && !isStandalone && !isChannelPicker && (
              <IconButton
                className={cx({ [classes.spotlightButton]: isSpotlightChannel() })}
                data-testid={testIds.channelSpotlightButton}
                title={t('epg.channels.spotlight_tooltip')}
                size="medium"
                placement="left"
                onClick={handleSpotlightClick}
              >
                <Search fontSize="small" />
              </IconButton>
            )}
            {!isContentServiceChannel && !isChannelPicker && !isStandalone && (
              <IconButton
                data-testid={testIds.editChannelButton}
                title={t('edit_channel')}
                placement="left"
                onClick={handleEdit}
                size="medium"
              >
                <Edit fontSize="small" />
              </IconButton>
            )}
            {isCategoryChannel && (
              <IconButton
                data-testid={testIds.removeChannelButton}
                title={t('tooltips.remove_channel')}
                onClick={removeChannel}
                size="medium"
              >
                <Delete fontSize="small" />
              </IconButton>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default EPGChannelItem;
